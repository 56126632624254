import CircleType from 'circletype'
import $ from 'jquery'

/* circle text */

const e = document.getElementById('footer-circle-title')
if (e) {
    const circleType = new CircleType(e)
    circleType.dir(1)
}

/* loading */

//$(window).on('load', function(event) {
window.onload = function(){
    console.log('window width', window.innerWidth)    
    const isPc = window.innerWidth >= 900
    
    function changeLogoColor () {
        const callback = ( event ) => {
            let currentColor = event.currentTarget.style.backgroundColor

            let newColor

            
            switch (currentColor) {
                case "rgb(255, 233, 0)":
                    newColor = "rgb(18, 18, 18)"
                    break
                case "rgb(18, 18, 18)":
                    newColor = "rgb(8, 60, 148)"
                    break
                case "rgb(8, 60, 148)":
                    newColor = "rgb(88, 164, 28)"
                    break
                case "rgb(88, 164, 28)":
                    newColor = "rgb(220, 28, 28)"
                    break
                case "rgb(220, 28, 28)":
                    newColor = "rgb(255, 233, 0)"
                    break
                default:
                    newColor = "rgb(18, 18, 18)"
                    break
            }
            
            event.currentTarget.style.backgroundColor = newColor
        }
        
        const moreReadBar = document.getElementById('more-read-container')
        if(moreReadBar) {
            moreReadBar.addEventListener("mouseenter", callback, false);   
            moreReadBar.addEventListener("click", callback, { capture: true });   

        }
    }

    
    function hideMv() {
        $('#load-mv-mo').hide()
        $('#load-mv-pc').hide()
        canvasProcess()
        changeLogoColor()

        setTimeout(() => {
            const el = document.getElementsByClassName('main-content-container')[0]
            el.style.visibility = "visible"
            $('#fixed-footer').fadeIn(1000)
            $('#header').fadeIn(1000)
            $('#abount-title')?.fadeIn(1000)
        }, 1000)
    }

        //if (!(window.location.pathname.match(/index.html$/) || window.location.pathname == "/")) {    
    if (!(window.location.pathname.match(/index.html$/) || window.location.pathname == "/") || localStorage.getItem("movieDone")) {
        hideMv()
        return
    } else {
        $('body').addClass('bg-yellow')
    }

    const moMv = document.getElementById('load-mv-mo')
    const pcMv = document.getElementById('load-mv-pc')

    if (!isPc) {
        console.log('load mobile mv')
        $(moMv).show()
        $('body').removeClass('bg-yellow')
        moMv.addEventListener('ended', hideMv, false)
    }

    if (isPc) {
        console.log('load pc mv')    
        $(pcMv).show()
        $('body').removeClass('bg-yellow')
        pcMv.addEventListener('ended', hideMv, false)
    }

    localStorage.setItem('movieDone', true);
};



/* scroll in animation */

$(window).on('scroll', function (){
    $('.strong-char-square').each(function(){
        var elemPos = $(this).offset().top
        var scroll = $(window).scrollTop()
        var windowHeight = $(window).height()

        if (scroll > elemPos - windowHeight){
            $(this).addClass('slide-right')
        }
    })


    $('.container-title-strong-char').each(function(){
        var elemPos = $(this).offset().top
        var scroll = $(window).scrollTop()
        var windowHeight = $(window).height()
        if (scroll > elemPos - windowHeight - 100){
            $(this).addClass('slide-up')
        }
    })
})




/* mouse pointer */

const mouseElm = $(".mouse");

function Cursor(element, options) {
    function stringNode(node) {
        let tmpNode = document.createElement("div")
        tmpNode.appendChild(node.cloneNode(true))
        return tmpNode.innerHTML
    }

    function setSize(size, scale) {
        if (scale) {
            scale = `${scale}`
            element.css({
                "transform" : `scale(${options.hoverSize})`,
                "opacity" : ".6",
                "left": "-100px",
                "top": "-100px",
            })
        } else {
            size = parseInt(size)
            element.css({
                width: size + "px",
                height: size + "px",
                transform: "none",
                opacity : "0.7"
            })
        }
    }

  setSize(options.size)

  document.onmousemove = e => {
    element.css({
        left: e.clientX - parseInt(options.size) / 2 + "px",
        top: e.clientY - parseInt(options.size) / 2 + "px"
    })
    let elementType = stringNode(e.target)
        .replace(/\</g, "")
        .split(">")[0]
        .split(" ")[0]
    if (["a", "button", "input"].includes(elementType))
        setSize(options.size, options.hoverSize)
    else setSize(options.size)
  }

    document.ontouchmove = e => {
        element.css({
            left: e.touches[0].clientX - parseInt(options.size) / 2 + "px",
            top: e.touches[0].clientY - parseInt(options.size) / 2 + "px"
        })
        let elementType = stringNode(e.target)
            .replace(/\</g, "")
            .split(">")[0]
            .split(" ")[0]
        if (["a", "button", "input"].includes(elementType))
            setSize(options.size, options.hoverSize)
        else setSize(options.size)
    }
}

Cursor(mouseElm, {
    size: "30px", // Width and Height
    hoverSize: 0.5 // Scale size
});

/* Title space */

//let canvas = document.querySelector("#fryth-logo")
let canvas = null

if (canvas) {
    let ctx = canvas.getContext("2d")
    let particles = []
    let amount = 0
    let mouse = { x: 0, y: 0 }
    let radius = 1

    let colors = ["#DF1F1C","#51A427", "#003D90", "#1A1311", "#FFE900"]

    let ww = canvas.width = window.innerWidth
    let wh = canvas.height = window.innerHeight

    let isPC = ww > 800

    ww = ww * window.devicePixelRatio
    wh = wh * window.devicePixelRatio

    function Particle(x,y){
        this.x =  Math.random() * ww
        this.y =  Math.random() * wh
        this.dest = {
            x: x,
            y: y
        }
        this.r = isPC ? (Math.random() * 10 + 20) : Math.random() * 4 + 8
        this.randn1 = Math.floor(Math.random() * 3)
        this.randn2 = Math.floor(Math.random() * 3)
        this.randn3 = Math.floor(Math.random() * 6)
        this.vx = (Math.random() - 0.5) * 20
        this.vy = (Math.random() - 0.5) * 20
        this.accX = 0
        this.accY = 0
        this.friction = Math.random() * 0.05 + 0.94

        this.color = colors[Math.floor(Math.random()*6)]
    }

    Particle.prototype.render = function() {
        this.accX = (this.dest.x - this.x) / 300
        this.accY = (this.dest.y - this.y) / 300
        this.vx += this.accX
        this.vy += this.accY
        this.vx *= this.friction
        this.vy *= this.friction

        this.x += this.vx
        this.y += this.vy

        ctx.fillStyle = this.color
        ctx.beginPath()
        //ctx.arc(this.x, this.y, this.r, Math.PI * 2, false)
        //ctx.rect(this.x, this.y, this.r, this.r / 2.0 )

        ctx.moveTo(this.x, this.y)
        ctx.lineTo(this.x + this.r + this.randn1, this.y) 
        ctx.lineTo(this.x + this.r + this.randn1, this.y + this.r + this.randn2)
        ctx.lineTo(this.x + this.randn3, this.y + this.r + this.randn2) 
        ctx.lineTo(this.x, this.y) 
        ctx.fill()

        let a = this.x - mouse.x
        let b = this.y - mouse.y
        let distance = Math.sqrt( a*a + b*b )

        if( distance < (radius * 140) ){
            this.accX = (this.x - mouse.x) / 100
            this.accY = (this.y - mouse.y) / 100
            this.vx += this.accX
            this.vy += this.accY
        }
    }

    function onMouseMove(e){
        //$('#debug').text("onMouseMove")
        mouse.x = e.clientX
        mouse.y = e.clientY
    }

    function onTouchMove(e){
        //$('#debug').text("onTouchMove")
        if(e.touches.length > 0 ){
            mouse.x = e.touches[0].clientX
            mouse.y = e.touches[0].clientY
        }
    }

    function onTouchEnd(e){
        //$('#debug').text("onTouchEnd")
        mouse.x = -9999
        mouse.y = -9999
    }

    const currentWidth = window.innerWidth

    function initScene(){
        ww = canvas.width = window.innerWidth
        wh = canvas.height = window.innerHeight
        isPC = ww > 800

        ctx.clearRect(0, 0, canvas.width, canvas.height)

        ctx.font = "bold "+(ww/5)+"px sans-serif"
        ctx.textAlign = "center"
        ctx.fillText("FRYTH", ww/2, wh/2)

        var data = ctx.getImageData(0, 0, ww, wh).data
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.globalCompositeOperation = "screen"

        particles = []
        const inter = isPC ? Math.round(ww/50) : Math.round(ww/40)
        //const inter = Math.round(ww/100)
        for(var i=0;i<ww;i+=inter){
            for(var j=0;j<wh;j+=inter){
                if(data[ ((i + j*ww)*4) + 3] > 150){
                    particles.push(new Particle(i,j))
                }
            }
        }
        amount = particles.length
    }

    function onMouseClick(e){
        radius = 10
        e.preventDefault()
        //$('a#to-home').fadeOut(500)
        $("#fryth-logo").fadeOut(500)
        setTimeout(function(){
            window.location = "/home.html"
        }, 300)
    }

    function render(a) {
        //requestAnimationFrame(render)
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        for (var i = 0; i < amount; i++) {
            particles[i].render()
        }
    }

    function resize() {
        if (currentWidth == window.innerWidth) {
            return
        }

        initScene()
    }

    window.addEventListener("resize", resize)
    window.addEventListener("mousemove", onMouseMove)
    window.addEventListener("touchmove", onTouchMove)
    window.addEventListener("click", onMouseClick)
    window.addEventListener("touchend", onTouchEnd)

    initScene()
    //requestAnimationFrame(render)
}


/* top perticles */

window.requestAnimFrame = (function () {
    return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (callback) {
        window.setTimeout(callback, 1000 / 60)
    }
})()

import importImages from './src/images/forCanvas/*.png'

function canvasProcess () {
    var canvasWrap = document.querySelector('#canvas-wrap');
    var canvas = document.querySelector('#canvas-container');

    if (!canvasWrap || !canvas) {
        return
    }

    var ctx = canvas.getContext('2d');

    var center = {};    // Canvas中央
    var dots = [];      // パーティクル配列

    const currentPath = window.location.pathname
    const isCompanyPath = currentPath === "/company.html"
    
    var density = isCompanyPath ? Math.floor(30 * 1.5) : 30   //パーティクルの数
    
    var colors = ["#FFE900AA", "#1A1311AA", "#003D90AA", "#51A427AA", "#DF1F1CAA"]
    var texts = ["F", "R", "Y", "T", "H"]
    var pcSizes = [48, 78, 100, 150, 200]
    var mobileSizes = [36, 62, 88, 112, 134]
    var baseSize = 48;   // 大きさ
    var baseSpeed = 10; // スピード
    let mouse = { x: -9999, y: -9999}
    let currentWidth = window.innerWidth
    let isPC = currentWidth > 800
    let images = Object.keys(importImages)


    var Dot = function (id) {
        this.id = id
        this.isImageDot = isCompanyPath || this.id < images.length

        if (this.isImageDot) {
            this.image = isCompanyPath ? images[this.id % 5] : images[this.id]

            this.img = new Image()
            this.imgLoaded = false
            this.img.addEventListener("load", function() {
                this.imgLoaded = true
            }.bind(this), false)

            this.img.src = importImages[this.image]
            this.size = 12

            this.speed = baseSpeed + Math.floor(Math.random() * 100)
        } else {
            this.size = isPC ? pcSizes[Math.floor( Math.random() * 5 )] : mobileSizes[Math.floor( Math.random() * 5 )]
            let rnam = ~~(Math.random() * 5)
            this.color = colors[rnam]
            this.text = texts[rnam]
            this.speed = this.size / baseSpeed
            this.isFill = Math.floor(Math.random() * 2)
        }

        const randomA = Math.floor(Math.random() * 2)
        const randomB = Math.floor(Math.random() * 2)

        if (randomA == 0) {
            this.pos = {
                x: randomB == 0 ? -200 : canvas.width + 200,
                y: Math.random() * canvas.height
            }
        } else {
            this.pos = {
                x: Math.random() * canvas.width,
                y: randomB == 0 ? -200 : canvas.height + 200,
            }
        }

        
        var rot = Math.random() * 360  // ランダムな角度
        var angle = rot * Math.PI / 180
        this.vec = {    // 移動方向
            x: Math.cos(angle) * 10,
            y: Math.sin(angle) * 10
        }

        this.dVec = {
            x: Math.cos(angle),
            y: Math.sin(angle)
        }

        this.friction = Math.random() * 0.04 + 0.94
    }


    Dot.prototype = {
        draw: function() {
            if (this.isImageDot) {
                if (this.imgLoaded) {
                    ctx.drawImage(this.img, this.pos.x, this.pos.y, this.img.width * 0.4, this.img.height * 0.4)
                }
            } else {
                ctx.fillStyle = this.color
                ctx.strokeStyle = this.color
                ctx.beginPath()
                ctx.font = `${this.size}px co-arabic`

                if (this.isFill) {
                    ctx.fillText(this.text, this.pos.x, this.pos.y)
                } else {
                    ctx.strokeText(this.text, this.pos.x, this.pos.y)
                }
            }
        },
        update: function() {
            this.draw();

            let a = this.pos.x - mouse.x
            let b = this.pos.y - mouse.y
            let distance = Math.sqrt( a*a + b*b )

            //if( distance < (isPC ? 140 : 100) * (isPC ? this.size / 50 : this.size / 40) * 0.5){
            if (distance < 140) {
                this.accX = (this.pos.x - mouse.x)/500
                this.accY = (this.pos.y - mouse.y)/500
                this.vec.x += this.accX
                this.vec.y += this.accY
            }

            this.pos.x += this.vec.x;
            this.pos.y += this.vec.y;

            if (Math.abs(this.vec.x) > Math.abs(this.dVec.x)) {
                this.vec.x *= this.friction
            }
            if (Math.abs(this.vec.y) > Math.abs(this.dVec.y)) {
                this.vec.y *= this.friction
            }


            // 画面外に出たら反対へ再配置
            const borderPX = 90

            if(this.pos.x > canvas.width + borderPX) {
                this.pos.x = -borderPX
            } else if(this.pos.x < -(borderPX + 50)) {
                this.pos.x = canvas.width + borderPX
            } else if(this.pos.y > canvas.height + borderPX) {
                this.pos.y = -borderPX
            } else if(this.pos.y < -borderPX) {
                this.pos.y = canvas.height + borderPX
            }
        }
    };

    function update() {
        requestAnimFrame(update);
        // 描画をクリアー
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        for (var i = 0; i < density; i++) {
            dots[i].update()
        }
    }

    function init() {
        const dpr = window.devicePixelRatio || 1
        //ctx.imageSmoothingQuality = "high"
        // canvasにコンテンツサイズをセット
        // canvas.setAttribute("width", canvas.width * dpr)
        // canvas.setAttribute("height", canvas.height * dpr)
        canvas.width = window.innerWidth
        canvas.height = window.innerHeight

        // canvas中央をセット
        center.x = canvas.width / 2
        center.y = canvas.height / 2

        // densityの数だけパーティクルを生成
        for (var i = 0; i < density; i++) {
            dots.push(new Dot(i))
        }

        update()
    }

    function resize() {
        if (currentWidth == window.innerWidth) {
            return
        }

        init()
    }

    function onMouseMove(e){
        mouse.x = e.clientX
        mouse.y = e.clientY
    }

    function onTouchStart(e) {
        if(e.touches.length > 0 ){
            mouse.x = e.touches[0].clientX
            mouse.y = e.touches[0].clientY
        }
    }

    function onTouchMove(e){
        if(e.touches.length > 0 ){
            mouse.x = e.touches[0].clientX
            mouse.y = e.touches[0].clientY
        }
    }

    function onTouchEnd(e){
        mouse.x = -9999
        mouse.y = -9999
    }

    window.addEventListener("resize", resize)
    window.addEventListener("mousemove", onMouseMove)
    window.addEventListener("touchmove", onTouchMove)
    window.addEventListener("touchstart", onTouchStart)
    window.addEventListener("touchend", onTouchEnd)
    window.addEventListener("touchcancel", onTouchEnd);

    init();
}